body {
  background-color: #F2F2F2
}

.App {
  text-align: center;
  background-color: #F2F2F2
}

.hover-underline:hover {
  text-decoration: underline;
}

h4 {
  margin-top: 8px;
  margin-bottom: 8px;
}

p {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
}
